import { DAppClient } from "@airgap/beacon-sdk";

const client = new DAppClient({ name: 'My Sample DApp' });

client
  .requestPermissions() // Send a permission request and automatically show UI to the user to select his favorite wallet
  .then((permissions) => {
    // Account that has been shared by the wallet
    document.getElementById('wallet-address').innerHTML = '<small>FooF Wallet ID:</small><br><br><em><strong>' + permissions['address'] + '</strong></em>';
    console.log('got permissions', permissions['address']);
  })
  .catch((error) => console.log(error))